
import { Component, Vue, Watch } from "vue-property-decorator";


@Component({
  components: {
    TotalInteractionsChart: () => import('@/components/charts/TotalInteractionsChart.vue'),
    InteractionsPerPartnerChart: () => import('@/components/charts/InteractionsPerPartnerChart.vue'),
    TotalInteractionsPerType: () => import('@/components/charts/TotalInteractionsPerType.vue'),
    InteractionTypesperHour: () => import('@/components/charts/InteractionTypesperHour.vue'),
    TotalInteractionsChartInfoCard: () => import('@/components/charts/info/TotalInteractionsChartInfoCard.vue'),
    InteractionsPerPartnerChartInfoCard: () => import('@/components/charts/info/InteractionsPerPartnerChartInfoCard.vue')
  }
})
export default class Interactions extends Vue {
  interactionsFilter = {
    from: new Date(2022, 12, 12),
    to: new Date(2023, 1, 1)
  }

  dateRangeSelector = {
    fromToggle: false,
    from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7).toISOString().substring(0, 10),
    toToggle: false,
    to: new Date().toISOString().substring(0, 10),
  }

  infoCards = {
    totalInteractionsChart: false,
    interactionsPerPartnerChart: false,
  }

  @Watch('dateRangeSelector.from', { immediate: true })
  @Watch('dateRangeSelector.to')
  onDateSelect() {
    this.interactionsFilter.from = new Date(this.dateRangeSelector.from);
    this.interactionsFilter.to = new Date(this.dateRangeSelector.to);
  }

  formatDate(date: Date) {
    return date.toLocaleDateString()
  }
}
